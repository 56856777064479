import { useEffect, useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap"
import { Helmet } from "react-helmet"
import { useForm } from "react-hook-form";


export const RefundApplication = ({ t, clientToken }) => {
    const { register, handleSubmit, reset, formState: { errors }, setValue, clearErrors } = useForm();
    const [selDocName, setSelDocName] = useState()
    const submit = (data) => {
        console.log(data)
    }

    useEffect(() => {
        console.log(errors.lastname)
        console.log(errors.lastname?.message)
        // console.log(errors.lastname === null ? false : true)
    }, [])

    return (
        <>
            <Helmet>
                <title>{t("mainTag.title")}</title>
                <meta name="description" content='Первое в России шоу-пространство "Ленинград Центр"'></meta>
                <meta name="og:title" content={`${t("mainTag.title")}`} />
                <meta name="og:description" content='Первое в России шоу-пространство "Ленинград Центр"' />
                <meta name="keywords" content="шоу- пространство, шоу пространство, ленинград центр, центр ленинград, мюзикл, шоу, представление, афиша" c />
                {/* <meta property="og:image" content={data.headLayoutImageType ? `/api/shows/head-wide-img/${data.id})` : "/Show/091A8332.jpg"} /> */}
            </Helmet>
            <div className="about-haed-layout mb-3">
                <div className="about-head-layout-img" style={{ backgroundImage: `url("/AboutProjcet/about-project.jpg")` }}>
                </div>
                <div className="about-head-mask">
                    <div className="justify-content-end ms-auto me-auto justify-content-end rounded-3"
                        style={{ width: "70%", backgroundColor: "#00000006", padding: "1rem" }}>
                    </div>
                </div>
            </div>
            <Container>
                <Row className="justify-content-center">
                    <Col lg={8}>
                        <Form className="justify-content-center" onSubmit={handleSubmit(submit)}>
                            <div className="d-md-flex gap-1 justify-content-center mb-2">
                                <Form.Group className="flex-fill mb-1">
                                    <>
                                        <Form.Label htmlFor="lastname">{t("form.lastName")}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="lastname"
                                            placeholder={t("form.lastName")}
                                            {...register("lastname", { required: { value: true, message: "Заполните пожалуйсто поле" } })}
                                            isInvalid={errors.lastname}
                                        // isValid
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.lastname?.message}
                                        </Form.Control.Feedback>
                                    </>
                                </Form.Group>
                                <Form.Group className="flex-fill mb-1">
                                    <>
                                        <Form.Label htmlFor="firstName">{t("form.firstName")}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="firstName"
                                            placeholder={t("form.firstName")}
                                            {...register("firstname", { required: { value: true, message: "Заполните пожалуйсто поле" } })}
                                            isInvalid={errors.firstname}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.firstname?.message}
                                        </Form.Control.Feedback>
                                    </>
                                </Form.Group>
                                <Form.Group className="flex-fill mb-1">
                                    <>
                                        <Form.Label htmlFor="patronymic">{t("form.patronymic")}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="patronymic"
                                            placeholder={t("form.patronymic")}
                                            {...register("patronymic")}
                                        />
                                    </>
                                </Form.Group>
                            </div>
                            <Form.Group className="border-top pt-3">
                                <Form.Label>Документ удостоверяющий личность</Form.Label>
                                <div className="mb-1 flex-fill">
                                    <InputGroup>
                                        <InputGroup.Text>
                                            Документ
                                        </InputGroup.Text>
                                        <Form.Select
                                            {...register("selDocName", { onChange: (e) => { setSelDocName(e.target.value) }, required: { value: true } })}>
                                            <option value={1}>Паспорт гражданина РФ</option>
                                            <option value={2}>Загранпаспорт</option>
                                            <option value={3}>Военный билет</option>
                                            <option value={4}>Иной документ</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.selDocName}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </div>
                                {selDocName == 4 &&
                                    <Form.Group className="gap-1 justify-content-center">
                                        <Form.Label>{t("form.docName")}</Form.Label>
                                        <Form.Control placeholder={t("form.docName")}
                                            className="mb-1 flex-fill"
                                            {...register("docName", { required: { value: true, message: "" } })}
                                            isInvalid={errors.docName}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Укажите наименование документа
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                }
                                <div className="d-md-flex gap-1 justify-content-center mb-2">
                                    <Form.Group className="flex-fill mb-1">
                                        <>
                                            <Form.Label htmlFor="seriesDoc">Серия</Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="seriesDoc"
                                                placeholder={"Серия"}
                                                {...register("seriesDoc", { required: { value: true, message: "" } })}
                                                isInvalid={errors.seriesDoc}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Укажите серию документа
                                            </Form.Control.Feedback>
                                        </>
                                    </Form.Group>
                                    <Form.Group className="flex-fill mb-1">
                                        <>
                                            <Form.Label htmlFor="numberDoc">Номер</Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="numberDoc"
                                                placeholder={"Номер"}
                                                {...register("numberDoc", { required: { value: true, message: "" } })}
                                                isInvalid={errors.numberDoc}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Укажите номер документа
                                            </Form.Control.Feedback>
                                        </>
                                    </Form.Group>
                                    <Form.Group className="flex-fill mb-1">
                                        <>
                                            <Form.Label htmlFor="seriesDoc">Дата выдачи</Form.Label>
                                            <Form.Control
                                                type="date"
                                                {...register("issuedDoc", { required: { value: true, message: "" } })}
                                                id="issuedDoc"
                                                placeholder={"Дата выдачи"}
                                                isInvalid={errors.issuedDoc}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Укажите дату выдачи документа
                                            </Form.Control.Feedback>
                                        </>
                                    </Form.Group>
                                </div>
                                <div className="d-md-flex gap-1 justify-content-center mb-2">
                                    <Form.Group className="gap-1 flex-fill">
                                        <Form.Label>Кем выдан</Form.Label>
                                        <Form.Control placeholder={"Кем выдан"}
                                            {...register("issuingAuthority", { required: { value: true, message: "" } })}
                                            isInvalid={errors.issuingAuthority}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Укажите дату выдачи документа
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </Form.Group>
                            <Button type="submit">Подготовит</Button>
                        </Form>
                    </Col>
                </Row>
            </Container >
        </>
    )
}